import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/graduateClassInfo/pageList", formData);
}

function restore(id) {
  return request.get(constant.serverUrl + "/base/graduateClassInfo/restore/" + id);
}

export default {
    pageList,restore
}